var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register"},[_c('Toasts',{ref:"Toasts"}),_c('PlgUsersApi',{ref:"PlgUsersApi"}),_c('div',{staticClass:"container-scroller"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper d-flex align-items-center auth"},[_c('div',{staticClass:"row w-100 flex-grow"},[_c('div',{staticClass:"col-lg-6 mx-auto"},[_c('div',{staticClass:"bg-white border rounded text-left p-5"},[_vm._m(0),_c('h4',[_vm._v("Novo por aqui?")]),_c('h5',{staticClass:"font-weight-light"},[_vm._v("Para se cadastrar é fácil!")]),_c('h6',{staticClass:"font-weight-light"},[_vm._v(" Basta preencher os campos abaixo: ")]),_c('b-form',{staticClass:"pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.userRegisterApi.apply(null, arguments)}}},[_c('b-form-group',[_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.userData.name.$error,
                    },attrs:{"id":"inputUsername","placeholder":"Nome Completo"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),(_vm.submitted && _vm.$v.userData.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.name.required)?_c('span',[_vm._v("Nome é obrigatório.")]):_vm._e()]):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.userData.email.$error,
                        },attrs:{"id":"inputEmail","placeholder":"Email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),(_vm.submitted && _vm.$v.userData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.email.required)?_c('span',[_vm._v("Email é obrigatório.")]):_vm._e()]):_vm._e(),(_vm.submitted && _vm.$v.userData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.email.email)?_c('span',[_vm._v("Email deve ser válido.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],class:{
                          'is-invalid':
                            _vm.submitted && _vm.$v.userData.cellphone.$error,
                        },attrs:{"type":"tel","id":"inputCellphone","placeholder":"Celular"},model:{value:(_vm.userData.cellphone),callback:function ($$v) {_vm.$set(_vm.userData, "cellphone", $$v)},expression:"userData.cellphone"}}),(_vm.submitted && _vm.$v.userData.cellphone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.cellphone.required)?_c('span',[_vm._v("Celular é obrigatório.")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-input-group',[(!_vm.userData.isCompany)?_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.userData.federalId.$error,
                      },attrs:{"placeholder":"CPF","type":"text"},model:{value:(_vm.userData.federalId),callback:function ($$v) {_vm.$set(_vm.userData, "federalId", $$v)},expression:"userData.federalId"}}):_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.userData.federalId.$error,
                      },attrs:{"type":"text","placeholder":"CNPJ"},model:{value:(_vm.userData.federalId),callback:function ($$v) {_vm.$set(_vm.userData, "federalId", $$v)},expression:"userData.federalId"}}),_c('b-input-group-append',[_c('b-form-radio-group',{attrs:{"button-variant":"outline-primary","options":_vm.documents,"aria-describedby":ariaDescribedby,"name":"radios-btn-default","size":"sm","buttons":""},model:{value:(_vm.userData.isCompany),callback:function ($$v) {_vm.$set(_vm.userData, "isCompany", $$v)},expression:"userData.isCompany"}})],1),(_vm.submitted && _vm.$v.userData.federalId.$error)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Documento inválido!")])]):_vm._e()],1)]}}])}),_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.userData.password.$error,
                      },attrs:{"type":_vm.seePassword ? 'text' : 'password',"id":"inputPassword","placeholder":"Senha"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-button',{class:_vm.passwordIcon,staticStyle:{"color":"#fff"},attrs:{"aria-hidden":"true","variant":"primary","size":"sm","squared":""},on:{"click":function($event){_vm.seePassword = !_vm.seePassword}}}),(_vm.submitted && _vm.$v.userData.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.password.required)?_c('span',[_vm._v("Senha é obrigatória.")]):_vm._e()]):_vm._e()],1)],1),_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.userData.rePassword.$error,
                      },attrs:{"type":_vm.seeConfirmPassword ? 'text' : 'password',"id":"inputRePassword","placeholder":"Confirme a senha"},model:{value:(_vm.userData.rePassword),callback:function ($$v) {_vm.$set(_vm.userData, "rePassword", $$v)},expression:"userData.rePassword"}}),_c('b-button',{class:_vm.confirmPasswordIcon,staticStyle:{"color":"#fff"},attrs:{"aria-hidden":"true","variant":"primary","size":"sm","squared":""},on:{"click":function($event){_vm.seeConfirmPassword = !_vm.seeConfirmPassword}}}),(_vm.submitted && _vm.$v.userData.rePassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.rePassword.required)?_c('span',[_vm._v("Confirme a senha.")]):_vm._e()]):_vm._e(),(_vm.submitted && _vm.$v.userData.rePassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(
                          !_vm.$v.userData.rePassword.sameAsPassword &&
                          _vm.$v.userData.rePassword.required
                        )?_c('span',[_vm._v("Senhas não conferem!")]):_vm._e()]):_vm._e()],1)],1),_c('div',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.zipCode.$error,
                          },attrs:{"id":"inputZipCode","placeholder":"CEP"},on:{"change":_vm.getZipCodeData},model:{value:(_vm.userData.zipCode),callback:function ($$v) {_vm.$set(_vm.userData, "zipCode", $$v)},expression:"userData.zipCode"}}),(_vm.submitted && _vm.$v.userData.zipCode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.zipCode.required)?_c('span',[_vm._v("CEP é obrigatório.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"form-group"},[_c('b-form-input',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.street.$error,
                          },attrs:{"id":"inputAddress","placeholder":"Logradouro"},model:{value:(_vm.userData.street),callback:function ($$v) {_vm.$set(_vm.userData, "street", $$v)},expression:"userData.street"}}),(_vm.submitted && _vm.$v.userData.street.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.street.required)?_c('span',[_vm._v("Logradouro é obrigatório.")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-input',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.district.$error,
                          },attrs:{"id":"inputQuarter","placeholder":"Bairro"},model:{value:(_vm.userData.district),callback:function ($$v) {_vm.$set(_vm.userData, "district", $$v)},expression:"userData.district"}}),(_vm.submitted && _vm.$v.userData.district.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.district.required)?_c('span',[_vm._v("Bairro é obrigatório.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.houseNumber.$error,
                          },attrs:{"id":"inputHouseNumber","placeholder":"Número"},model:{value:(_vm.userData.houseNumber),callback:function ($$v) {_vm.$set(_vm.userData, "houseNumber", $$v)},expression:"userData.houseNumber"}}),(_vm.submitted && _vm.$v.userData.houseNumber.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.houseNumber.required)?_c('span',[_vm._v("Número é obrigatório.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"id":"inputAddressComplement","placeholder":"Complemento"},model:{value:(_vm.userData.addressComplement),callback:function ($$v) {_vm.$set(_vm.userData, "addressComplement", $$v)},expression:"userData.addressComplement"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-select',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.state.$error,
                            'form-control': true,
                            'select-field': true,
                            'placeholder-text': !_vm.userData.state,
                          },attrs:{"options":_vm.states,"id":"inputState","size":"md"},model:{value:(_vm.userData.state),callback:function ($$v) {_vm.$set(_vm.userData, "state", $$v)},expression:"userData.state"}}),(_vm.submitted && _vm.$v.userData.state.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.state.required)?_c('span',[_vm._v(" Estado é obrigatório. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-select',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.userData.city.$error,
                            'form-control': true,
                            'select-field': true,
                            'placeholder-text': !_vm.userData.city,
                          },attrs:{"id":"inputCity","disabled":!_vm.userData.state,"options":_vm.citiesOptions},model:{value:(_vm.userData.city),callback:function ($$v) {_vm.$set(_vm.userData, "city", $$v)},expression:"userData.city"}}),(!_vm.userData.state)?_c('div',[_c('small',{staticClass:"text-muted font-wight-light"},[_c('i',{staticClass:"mdi mdi-alert"}),_vm._v(" Selecione um estado ")])]):_vm._e(),(_vm.submitted && _vm.$v.userData.city.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.city.required)?_c('span',[_vm._v("Cidade é obrigatória.")]):_vm._e()]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"mb-4"},[_c('b-form-group',{staticClass:"form-check"},[_c('label',{staticClass:"form-check-label text-muted"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.termsCheck),expression:"userData.termsCheck"}],class:{
                          'is-invalid':
                            _vm.submitted && _vm.$v.userData.termsCheck.$error,
                          'form-check-input': true,
                        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.userData.termsCheck)?_vm._i(_vm.userData.termsCheck,null)>-1:(_vm.userData.termsCheck)},on:{"change":function($event){var $$a=_vm.userData.termsCheck,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.userData, "termsCheck", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.userData, "termsCheck", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.userData, "termsCheck", $$c)}}}}),_vm._v(" Eu concordo com os Termos e Condições "),_c('i',{staticClass:"input-helper"}),(_vm.submitted && _vm.$v.userData.termsCheck.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.termsCheck.sameAsTrue)?_c('span',[_vm._v(" É necessário que você concorde com os termos! ")]):_vm._e()]):_vm._e()])])],1),_c('div',{staticClass:"mt-3"},[_c('b-button',{attrs:{"block":"","size":"lg","disabled":_vm.isLoading,"variant":"primary","type":"submit"}},[_c('div',{staticClass:"text-center align-items-center"},[(!_vm.isLoading)?_c('span',[_vm._v("ENVIAR")]):_c('b-spinner',{attrs:{"small":""}})],1)])],1),_c('div',{staticClass:"text-center mt-4 font-weight-light"},[_vm._v(" Já tem uma conta? "),_c('router-link',{staticClass:"text-primary",attrs:{"to":"login"}},[_vm._v("Entrar")])],1)],1)],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo.png")}})])
}]

export { render, staticRenderFns }